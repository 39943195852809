import React, { useEffect }  from "react";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import ReactGA from 'react-ga4';
import { GA_TRACKING_ID } from "./js/variables";

import Gems from "./pages/gems";
// import Four0Four from "./pages/404";

import "./App.css";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="/gems" />,
    },
    {
        path: "/gems",
        children: [
            {
                path: "/gems",
                element: <Gems />,
            },
        ],
    },
    {
        path: "*",
        element: <Navigate to="/gems" />,
    },
]);

const App = () => {
    useEffect(() => {
        const currentPage = window.location.pathname + window.location.search;
        console.log(currentPage);
        ReactGA.initialize(GA_TRACKING_ID);
        ReactGA.send({
            hitType: "pageView",
            page: currentPage,
        });
    }, []);

    return (
        <RouterProvider router={router} />
    );
};

export default App;
